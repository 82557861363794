.menu-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    padding: 50px;
    overflow-y: auto;
   
  }
  
  .menu-card {
    flex: 2; 
    background-color: rgb(19, 9, 53);
    color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 390px;
    /* height: 550px; */
    background-image: url('./1.png');
    transition: all 0.3s ease;
   
  }
  
  .menu-card:hover {
    background-color: rgb(24, 19, 10);
    color: white;
    background-image: url('./2.png');
   
  }
  
  .menu-card h2 {
    color: orange;
    margin-bottom: 10px;
    font-size: medium;
  }
  
  .menu-items h3 {
    margin-bottom: 5px;
    color: aliceblue;
  }
  
  .menu-items ul {
    list-style-type: none;
    padding: 0;
    font-size: small;
  }
  
  .menu-items ul li {
    margin-bottom: 5px;
    color: rgb(206, 200, 192);
  }
  

  
@media (max-width: 768px) {
    /* Maintain previous styles on mobile devices */
    .menu-container {
      flex-direction: column; /* Stack items vertically */
      height: auto; /* Allow container to expand based on content */
    padding: 5px;

    }
  
    .menu-card {
      flex: none; /* Reset flex property */
      margin-bottom: 20px; /* Add spacing between menu cards */
      width: auto;
    }
  }