.gallery {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 20px;
    margin-bottom: 80px;
    margin-left: 50px;
    margin-right: 50px;
  }
  
  .gallery-image {
    width: 100%;
    height: auto;
    cursor: pointer;
    transition: transform 0.2s;
    border: 2px solid #fff; /* Add a white border around each image */
    border-radius: 8px; /* Add rounded corners to the images */
  }
  
  .gallery-image:hover {
    transform: scale(1.05);
    border-color: #ff6600; /* Change border color on hover */
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
   
  }
  
  .overlay-image {
    max-width: 90%;
    max-height: 90%;
    border-radius: 25px;
  }
  
  .book-now-button {
    background-color: orange;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    margin:  auto; /* Center horizontally */
    text-align: center;
  }
  
  .book-now-button:hover {
    animation: pulse 1s infinite;
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  